import { CONTENT_HEADERS } from '~/constants/header.constants'
import { MEDIA_TYPES } from '~/constants/media.constants'

// Headers
export const useActiveContentHeader = () => useState<ContentHeaders>('activeContentHeader', () => Object.values(CONTENT_HEADERS)[0])
export const useContentHeaders = () => useState<ContentHeaders[]>('contentHeaders', () => Object.values(CONTENT_HEADERS))

// Containers
export const usePostContainers = () => useState<MediaContainer[] | null>('postContainers', () => null)
export const useStoryContainers = () => useState<MediaContainer[] | null>('storyContainers', () => null)
export const useReelContainers = () => useState<MediaContainer[] | null>('reelContainers', () => null)
export const useLoadingReels = () => useState<boolean>('loadingReels', () => false)
export const useLoadingPosts = () => useState<boolean>('loadingPosts', () => false)
export const useLoadingStories = () => useState<boolean>('loadingStories', () => false)

// Link
export const useUser = () => useState<User | null>('user', () => null)
export const useBrand = () => useState<Brand | null>('brand', () => null)
export const useHideDrafts = () => useState<Boolean>('hideDrafts', () => true)

// Account
export const useAccount = () => useState<Account | null>('account', () => null)
export const useAccountList = () => useState<Account[] | null>('accountsList', () => null)

// Grid
export const useGrid = () => useState<GridItem[] | null>('grid', () => null)
export const useGridPagintion = () => useState<{ before: string, after: string } | undefined>('gridPagination', () => undefined)

// Post Types
export const useActivePostType = () => useState<PostTypes>('activePostType', () => MEDIA_TYPES.ALL)

// Post Modal
export const usePreviewItemModal = () => useState<previewItemModal | null>('mediaContainerModal', () => null)
